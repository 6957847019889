import React, { useState, useEffect } from "react";
import { Segment, Menu, Grid, Button } from "semantic-ui-react";

import _ from "lodash";
import { fetchWellnessForms, fetchWellnessQuestions } from "../../actions";
import { useDispatch, useSelector } from "react-redux";

import PatientConnect from "./connect";

const Index = () => {
  const [modalOpen, updateModalOpen] = useState(false);
  const wellnessForms = useSelector((state) => state.wellnessForm);
  const wellnessQuestions = useSelector((state) => state.wellnessQuestion);
  const user = useSelector((state) => state.user);
  const [activeIndex, updateActiveIndex] = useState(-1);
  const [activeItem, update_activeItem] = useState("Wellness");

  const [loading, update_loading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {}, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Patient Monitoring</h1>
      <PatientConnect />
    </div>
  );
};

export default Index;
