import React, { useState, useEffect } from "react";

import Monitoring from "../../components/monitoring";
import Layout from "../../components/layout";

const Index = () => {
  return (
    <Layout>
      <Monitoring />
    </Layout>
  );
};

export default Index;
