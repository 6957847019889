/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateTile = /* GraphQL */ `
  subscription OnCreateTile($filter: ModelSubscriptionTileFilterInput) {
    onCreateTile(filter: $filter) {
      id
      user_id
      user_name
      tile_id
      title
      destination
      icon
      destination_name
      index_number
      colour
      restrict_to_admin
      timestamp
      junction_page_selected
      JunctionPage
      alternative_link
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTile = /* GraphQL */ `
  subscription OnUpdateTile($filter: ModelSubscriptionTileFilterInput) {
    onUpdateTile(filter: $filter) {
      id
      user_id
      user_name
      tile_id
      title
      destination
      icon
      destination_name
      index_number
      colour
      restrict_to_admin
      timestamp
      junction_page_selected
      JunctionPage
      alternative_link
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTile = /* GraphQL */ `
  subscription OnDeleteTile($filter: ModelSubscriptionTileFilterInput) {
    onDeleteTile(filter: $filter) {
      id
      user_id
      user_name
      tile_id
      title
      destination
      icon
      destination_name
      index_number
      colour
      restrict_to_admin
      timestamp
      junction_page_selected
      JunctionPage
      alternative_link
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePage = /* GraphQL */ `
  subscription OnCreatePage($filter: ModelSubscriptionPageFilterInput) {
    onCreatePage(filter: $filter) {
      id
      user_id
      user_name
      title
      index_number
      page_id
      timestamp
      restrict_to_admin
      junction_page
      junction_data
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePage = /* GraphQL */ `
  subscription OnUpdatePage($filter: ModelSubscriptionPageFilterInput) {
    onUpdatePage(filter: $filter) {
      id
      user_id
      user_name
      title
      index_number
      page_id
      timestamp
      restrict_to_admin
      junction_page
      junction_data
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePage = /* GraphQL */ `
  subscription OnDeletePage($filter: ModelSubscriptionPageFilterInput) {
    onDeletePage(filter: $filter) {
      id
      user_id
      user_name
      title
      index_number
      page_id
      timestamp
      restrict_to_admin
      junction_page
      junction_data
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTrust = /* GraphQL */ `
  subscription OnCreateTrust($filter: ModelSubscriptionTrustFilterInput) {
    onCreateTrust(filter: $filter) {
      id
      user_id
      timestamp
      active
      image_url
      name
      full_name
      surveys
      trust_id
      trust_image
      user_name
      version
      supported_app_version
      default_pathway
      enable_pathway_selection
      pathways {
        items {
          id
          user_id
          user_name
          title
          cms_title
          timeline_data
          image_url
          hero_section
          wellness_forms_array
          url
          redirect
          version
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTrust = /* GraphQL */ `
  subscription OnUpdateTrust($filter: ModelSubscriptionTrustFilterInput) {
    onUpdateTrust(filter: $filter) {
      id
      user_id
      timestamp
      active
      image_url
      name
      full_name
      surveys
      trust_id
      trust_image
      user_name
      version
      supported_app_version
      default_pathway
      enable_pathway_selection
      pathways {
        items {
          id
          user_id
          user_name
          title
          cms_title
          timeline_data
          image_url
          hero_section
          wellness_forms_array
          url
          redirect
          version
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTrust = /* GraphQL */ `
  subscription OnDeleteTrust($filter: ModelSubscriptionTrustFilterInput) {
    onDeleteTrust(filter: $filter) {
      id
      user_id
      timestamp
      active
      image_url
      name
      full_name
      surveys
      trust_id
      trust_image
      user_name
      version
      supported_app_version
      default_pathway
      enable_pathway_selection
      pathways {
        items {
          id
          user_id
          user_name
          title
          cms_title
          timeline_data
          image_url
          hero_section
          wellness_forms_array
          url
          redirect
          version
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePathway = /* GraphQL */ `
  subscription OnCreatePathway($filter: ModelSubscriptionPathwayFilterInput) {
    onCreatePathway(filter: $filter) {
      id
      user_id
      user_name
      title
      cms_title
      timeline_data
      image_url
      hero_section
      wellness_forms_array
      url
      redirect
      version
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePathway = /* GraphQL */ `
  subscription OnUpdatePathway($filter: ModelSubscriptionPathwayFilterInput) {
    onUpdatePathway(filter: $filter) {
      id
      user_id
      user_name
      title
      cms_title
      timeline_data
      image_url
      hero_section
      wellness_forms_array
      url
      redirect
      version
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePathway = /* GraphQL */ `
  subscription OnDeletePathway($filter: ModelSubscriptionPathwayFilterInput) {
    onDeletePathway(filter: $filter) {
      id
      user_id
      user_name
      title
      cms_title
      timeline_data
      image_url
      hero_section
      wellness_forms_array
      url
      redirect
      version
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSection = /* GraphQL */ `
  subscription OnCreateSection($filter: ModelSubscriptionSectionFilterInput) {
    onCreateSection(filter: $filter) {
      id
      user_id
      timestamp
      colour
      destination
      ext_link
      icon
      image_hash
      image_url
      image_type
      image_style
      index_number
      original_image_key
      section_id
      text
      title
      type
      user_name
      data
      page_link_type
      alternative_link
      button_text
      checkbox_text
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSection = /* GraphQL */ `
  subscription OnUpdateSection($filter: ModelSubscriptionSectionFilterInput) {
    onUpdateSection(filter: $filter) {
      id
      user_id
      timestamp
      colour
      destination
      ext_link
      icon
      image_hash
      image_url
      image_type
      image_style
      index_number
      original_image_key
      section_id
      text
      title
      type
      user_name
      data
      page_link_type
      alternative_link
      button_text
      checkbox_text
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSection = /* GraphQL */ `
  subscription OnDeleteSection($filter: ModelSubscriptionSectionFilterInput) {
    onDeleteSection(filter: $filter) {
      id
      user_id
      timestamp
      colour
      destination
      ext_link
      icon
      image_hash
      image_url
      image_type
      image_style
      index_number
      original_image_key
      section_id
      text
      title
      type
      user_name
      data
      page_link_type
      alternative_link
      button_text
      checkbox_text
      createdAt
      updatedAt
    }
  }
`;
export const onCreateJunctionPage = /* GraphQL */ `
  subscription OnCreateJunctionPage(
    $filter: ModelSubscriptionJunctionPageFilterInput
  ) {
    onCreateJunctionPage(filter: $filter) {
      id
      user_id
      junction_data
      title
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateJunctionPage = /* GraphQL */ `
  subscription OnUpdateJunctionPage(
    $filter: ModelSubscriptionJunctionPageFilterInput
  ) {
    onUpdateJunctionPage(filter: $filter) {
      id
      user_id
      junction_data
      title
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteJunctionPage = /* GraphQL */ `
  subscription OnDeleteJunctionPage(
    $filter: ModelSubscriptionJunctionPageFilterInput
  ) {
    onDeleteJunctionPage(filter: $filter) {
      id
      user_id
      junction_data
      title
      createdAt
      updatedAt
    }
  }
`;
export const onCreateWellnessForm = /* GraphQL */ `
  subscription OnCreateWellnessForm(
    $filter: ModelSubscriptionWellnessFormFilterInput
  ) {
    onCreateWellnessForm(filter: $filter) {
      id
      user_name
      user_id
      title
      colour
      form_array
      icon
      index_number
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateWellnessForm = /* GraphQL */ `
  subscription OnUpdateWellnessForm(
    $filter: ModelSubscriptionWellnessFormFilterInput
  ) {
    onUpdateWellnessForm(filter: $filter) {
      id
      user_name
      user_id
      title
      colour
      form_array
      icon
      index_number
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteWellnessForm = /* GraphQL */ `
  subscription OnDeleteWellnessForm(
    $filter: ModelSubscriptionWellnessFormFilterInput
  ) {
    onDeleteWellnessForm(filter: $filter) {
      id
      user_name
      user_id
      title
      colour
      form_array
      icon
      index_number
      createdAt
      updatedAt
    }
  }
`;
export const onCreateQuestion = /* GraphQL */ `
  subscription OnCreateQuestion($filter: ModelSubscriptionQuestionFilterInput) {
    onCreateQuestion(filter: $filter) {
      id
      form_id
      user_name
      user_id
      title
      sub_title
      stub
      question
      answer_array
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateQuestion = /* GraphQL */ `
  subscription OnUpdateQuestion($filter: ModelSubscriptionQuestionFilterInput) {
    onUpdateQuestion(filter: $filter) {
      id
      form_id
      user_name
      user_id
      title
      sub_title
      stub
      question
      answer_array
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteQuestion = /* GraphQL */ `
  subscription OnDeleteQuestion($filter: ModelSubscriptionQuestionFilterInput) {
    onDeleteQuestion(filter: $filter) {
      id
      form_id
      user_name
      user_id
      title
      sub_title
      stub
      question
      answer_array
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTrigger = /* GraphQL */ `
  subscription OnCreateTrigger($filter: ModelSubscriptionTriggerFilterInput) {
    onCreateTrigger(filter: $filter) {
      id
      user_name
      user_id
      title
      text
      action
      destination
      call_to_action
      colour
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTrigger = /* GraphQL */ `
  subscription OnUpdateTrigger($filter: ModelSubscriptionTriggerFilterInput) {
    onUpdateTrigger(filter: $filter) {
      id
      user_name
      user_id
      title
      text
      action
      destination
      call_to_action
      colour
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTrigger = /* GraphQL */ `
  subscription OnDeleteTrigger($filter: ModelSubscriptionTriggerFilterInput) {
    onDeleteTrigger(filter: $filter) {
      id
      user_name
      user_id
      title
      text
      action
      destination
      call_to_action
      colour
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAnalytics = /* GraphQL */ `
  subscription OnCreateAnalytics(
    $filter: ModelSubscriptionAnalyticsFilterInput
  ) {
    onCreateAnalytics(filter: $filter) {
      id
      analytics_id
      meta_1
      meta_2
      meta_3
      data
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAnalytics = /* GraphQL */ `
  subscription OnUpdateAnalytics(
    $filter: ModelSubscriptionAnalyticsFilterInput
  ) {
    onUpdateAnalytics(filter: $filter) {
      id
      analytics_id
      meta_1
      meta_2
      meta_3
      data
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAnalytics = /* GraphQL */ `
  subscription OnDeleteAnalytics(
    $filter: ModelSubscriptionAnalyticsFilterInput
  ) {
    onDeleteAnalytics(filter: $filter) {
      id
      analytics_id
      meta_1
      meta_2
      meta_3
      data
      createdAt
      updatedAt
    }
  }
`;
export const onCreateImage = /* GraphQL */ `
  subscription OnCreateImage($filter: ModelSubscriptionImageFilterInput) {
    onCreateImage(filter: $filter) {
      id
      user_id
      title
      original_image
      image_url
      low_res_image_url
      hash
      cached_image
      data
      tags
      version_id
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateImage = /* GraphQL */ `
  subscription OnUpdateImage($filter: ModelSubscriptionImageFilterInput) {
    onUpdateImage(filter: $filter) {
      id
      user_id
      title
      original_image
      image_url
      low_res_image_url
      hash
      cached_image
      data
      tags
      version_id
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteImage = /* GraphQL */ `
  subscription OnDeleteImage($filter: ModelSubscriptionImageFilterInput) {
    onDeleteImage(filter: $filter) {
      id
      user_id
      title
      original_image
      image_url
      low_res_image_url
      hash
      cached_image
      data
      tags
      version_id
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSystemInfo = /* GraphQL */ `
  subscription OnCreateSystemInfo(
    $filter: ModelSubscriptionSystemInfoFilterInput
  ) {
    onCreateSystemInfo(filter: $filter) {
      id
      title
      domain
      type
      landing_option
      trust_id
      target
      data
      data1
      data2
      data3
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSystemInfo = /* GraphQL */ `
  subscription OnUpdateSystemInfo(
    $filter: ModelSubscriptionSystemInfoFilterInput
  ) {
    onUpdateSystemInfo(filter: $filter) {
      id
      title
      domain
      type
      landing_option
      trust_id
      target
      data
      data1
      data2
      data3
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSystemInfo = /* GraphQL */ `
  subscription OnDeleteSystemInfo(
    $filter: ModelSubscriptionSystemInfoFilterInput
  ) {
    onDeleteSystemInfo(filter: $filter) {
      id
      title
      domain
      type
      landing_option
      trust_id
      target
      data
      data1
      data2
      data3
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOnboardingInfo = /* GraphQL */ `
  subscription OnCreateOnboardingInfo(
    $filter: ModelSubscriptionOnboardingInfoFilterInput
  ) {
    onCreateOnboardingInfo(filter: $filter) {
      id
      title
      domain
      type
      landing_option
      trust_id
      target
      data
      data1
      data2
      data3
      message_title
      message_subtext
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOnboardingInfo = /* GraphQL */ `
  subscription OnUpdateOnboardingInfo(
    $filter: ModelSubscriptionOnboardingInfoFilterInput
  ) {
    onUpdateOnboardingInfo(filter: $filter) {
      id
      title
      domain
      type
      landing_option
      trust_id
      target
      data
      data1
      data2
      data3
      message_title
      message_subtext
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOnboardingInfo = /* GraphQL */ `
  subscription OnDeleteOnboardingInfo(
    $filter: ModelSubscriptionOnboardingInfoFilterInput
  ) {
    onDeleteOnboardingInfo(filter: $filter) {
      id
      title
      domain
      type
      landing_option
      trust_id
      target
      data
      data1
      data2
      data3
      message_title
      message_subtext
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
    onCreateUser(filter: $filter) {
      id
      user_hash
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
    onUpdateUser(filter: $filter) {
      id
      user_hash
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
    onDeleteUser(filter: $filter) {
      id
      user_hash
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCheck = /* GraphQL */ `
  subscription OnCreateCheck($filter: ModelSubscriptionCheckFilterInput) {
    onCreateCheck(filter: $filter) {
      id
      user_hash
      check_id
      checked
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCheck = /* GraphQL */ `
  subscription OnUpdateCheck($filter: ModelSubscriptionCheckFilterInput) {
    onUpdateCheck(filter: $filter) {
      id
      user_hash
      check_id
      checked
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCheck = /* GraphQL */ `
  subscription OnDeleteCheck($filter: ModelSubscriptionCheckFilterInput) {
    onDeleteCheck(filter: $filter) {
      id
      user_hash
      check_id
      checked
      createdAt
      updatedAt
    }
  }
`;
