import React, { useState, useEffect } from "react";
import { Button, Loader, Dimmer, Card, Icon } from "semantic-ui-react";

import _ from "lodash";
import { API, graphqlOperation } from "aws-amplify";
import {
  checkByUserHash,
  listChecks,
  getSection,
} from "../../../graphql/queries";

import { deleteCheck } from "../../../graphql/mutations";
import { onUpdateCheck } from "../../../graphql/subscriptions";

const Index = () => {
  const [loading, update_loading] = useState(false);
  const [user_checks, update_user_checks] = useState(null);
  const [checks_data, update_checks_data] = useState(null);
  const [a, update_a] = useState(null);
  const [b, update_b] = useState(null);
  const [c, update_c] = useState(null);
  const [d, update_d] = useState(null);
  const [e, update_e] = useState(null);
  const [f, update_f] = useState(null);

  const [list, update_list] = useState([]);

  function updateCheckHasHappened(data) {
    try {
      console.log(data);
      console.log(data.value.data.onUpdateCheck);
      const new_check_to_return = user_checks.map((c) => {
        if (c.id === data.value.data.onUpdateCheck.id) {
          return data.value.data.onUpdateCheck;
        }
        return c;
      });
      update_user_checks([...new_check_to_return]);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const subscription = API.graphql(graphqlOperation(onUpdateCheck)).subscribe(
      {
        next: ({ provider, value }) =>
          updateCheckHasHappened({ provider, value }),
        error: (error) => console.warn(error),
      }
    );
    // Stop receiving data updates from the subscription
    return () => {
      return subscription.unsubscribe();
    };
  }, [user_checks]);

  async function removeCheck(id) {
    console.log(id);
    const response = await API.graphql(
      graphqlOperation(deleteCheck, { input: { id: id } })
    );
    console.log(response);
  }

  async function listThem() {
    const response = await API.graphql(graphqlOperation(listChecks));
    update_list(response.data.listChecks.items);
  }

  async function connectUser() {
    update_loading(true);
    const response = await API.graphql(
      graphqlOperation(checkByUserHash, { user_hash: a + b + c + d + e + f })
    );
    update_user_checks(response.data.CheckByUserHash.items);

    const returned_user_checks = response.data.CheckByUserHash.items;
    var checks = await returned_user_checks.map(async (c) => {
      return await addUserCheck(c.check_id);
    });
    Promise.all(checks).then((values) => {
      update_checks_data(values);
    });

    update_loading(false);
  }

  async function addUserCheck(id) {
    console.log("trying to get section with id:");
    console.log(id);
    const response = await API.graphql(
      graphqlOperation(getSection, { id: id })
    );
    return response.data.getSection;
  }

  function updateConnectCode(name, value) {
    switch (name) {
      case "ssn-1":
        update_a(value);
        break;
      case "ssn-2":
        update_b(value);
        break;
      case "ssn-3":
        update_c(value);
        break;
      case "ssn-4":
        update_d(value);
        break;
      case "ssn-5":
        update_e(value);
        break;
      case "ssn-6":
        update_f(value);
        break;
        break;

      default:
        break;
    }
  }
  const handleChange = (e) => {
    console.log(e);

    updateConnectCode(e.target.name, e.target.value);
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");

    // Check if they hit the max character length
    if (value.length >= maxLength) {
      // Check if it's not the last input field
      if (parseInt(fieldIndex, 10) < 6) {
        // Get the next input field
        const nextSibling = document.querySelector(
          `input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`
        );

        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
  };

  if (loading) {
    return (
      <Dimmer active>
        <Loader size="large" content="Connecting to user data" />
      </Dimmer>
    );
  }
  if (checks_data !== null) {
    const checks = checks_data.map((check) => {
      const is__true = _.find(user_checks, { check_id: check.id }).checked;
      return (
        <Card id={check.id}>
          <Card.Content>
            <Card.Header>{check.title}</Card.Header>
            {/* <Card.Meta>{check.text}</Card.Meta> */}
            <Card.Description>
              {/* <div
                // className="section_text"
                style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}
                dangerouslySetInnerHTML={{ __html: check.text }}
              ></div> */}
            </Card.Description>
          </Card.Content>
          <Card.Content
            style={is__true ? { backgroundColor: "green", color: "white" } : {}}
            extra
          >
            <Icon name="check circle outline" style={{ color: "white" }} />
            {is__true ? "Checked" : "Not Checked"}
          </Card.Content>
        </Card>
      );
    });

    return (
      <>
        {/* <div>{JSON.stringify(user_checks)}</div> */}
        <Card.Group>{checks}</Card.Group>
      </>
    );
  }
  return (
    <div>
      {/* {a + b + c + d + e + f} */}
      <div style={{ margin: "auto", textAlign: "center", marginTop: 50 }}>
        <div>
          <input
            className="connect_input"
            name="ssn-1"
            type="number"
            value={a}
            onChange={(e) => handleChange(e)}
            maxLength={0}
          />
          <input
            className="connect_input"
            name="ssn-2"
            type="number"
            value={b}
            onChange={(e) => handleChange(e)}
            maxLength={1}
          />
          <input
            className="connect_input"
            name="ssn-3"
            type="number"
            value={c}
            onChange={(e) => handleChange(e)}
            maxLength={1}
          />
          <input
            className="connect_input"
            name="ssn-4"
            type="number"
            value={d}
            onChange={(e) => handleChange(e)}
            maxLength={1}
          />
          <input
            className="connect_input"
            name="ssn-5"
            type="number"
            value={e}
            onChange={(e) => handleChange(e)}
            maxLength={1}
          />
          <input
            className="connect_input"
            name="ssn-6"
            type="number"
            value={f}
            onChange={(e) => handleChange(e)}
            maxLength={1}
          />
        </div>
        <Button
          primary
          style={{ width: 470, marginTop: 30 }}
          onClick={() => connectUser()}
        >
          Connect to user
        </Button>
        {/* <div onClick={() => listThem()}>List</div>
        <div>
          {list.map((a) => {
            return <div onClick={() => removeCheck(a.id)}>{a.id}</div>;
          })}
        </div> */}
      </div>
    </div>
  );
};

export default Index;
